import React from 'react'
import Layout from '../../components/newdesign/layout'
import Testimonial from '../../components/Testimonial'
import Seo from '../../components/seo'
import classnames from 'classnames'
import alexImg from '../../images/alex.jpg'
import shareImage from '../../images/webinar/shareImg.png'
import dotsImage from '../../images/newdesign/dots.svg'
import CustomersPromo from '../../components/CustomersPromo'
import Helmet from 'react-helmet'

import * as css from '../../components/newdesign/ValueSection.module.css'

import * as ctaCss from '../../components/casestudy/caseStudyLayout.module.css'

const Ecommerce = () => {
  const embedHubspotForm = () => {
    if (window.hbspt) {
      window.hbspt.forms.create({
        region: "na1",
        target: '#hsform',
        portalId: "5432476",
        formId: "fc7f746e-1caa-4497-a991-64294230873a",
        version: "V2_PRERELEASE"
      })
    }
  }

  const handleScriptInject = ({scriptTags}) => {
    if (scriptTags) {
      const scriptTag = scriptTags[0];
      scriptTag.onload = embedHubspotForm;
    }
  }

  return <Layout nakedBar trialCta slackApp pricing>
    <Helmet onChangeClientState={(_newState, addedTags) => handleScriptInject(addedTags)}>
      <script onload={() => console.log('test')} charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/v2.js"></script>
    </Helmet>
    <Seo
      title="Webinar: analityka dla e-commerce"
      description={
        '6 Pazdziernika, 15:00 na ZOOM - opowiemy o podstawach analityki dla e-commerce'
      }
      keywords={['webinar', 'analityka', 'e-commerce', 'revenue', 'bi', 'analityka']}
      twitterImg={shareImage}
      mainpageImg={shareImage}
    />
    <div className={classnames('container', 'col-xxl-10', 'px-4', 'py-5')}>
      <div className={classnames('row', 'flex-lg-row-reverse', 'align-items-center', 'g-5', 'py-3')}>
        <div className={classnames('col-10', 'col-sm-8', 'col-lg-6')}>
          <img src={alexImg} className="d-block mx-lg-auto img-fluid" alt="Fakturownia logo" width="400" loading="lazy" />
          <p className='text-center'>Webinar poprowadzi: Aliaksei Kulbei, Co-Founder @ Probe</p>
        </div>
        <div className={classnames('col-lg-6')}>
          <h1 className={classnames('display-5', 'fw-bold', 'lh-1', 'mb-3')}>Webinar: analityka dla e-commerce</h1>
          <p className='lead'>
            Prowadzisz biznes e-commerce? Dobra analityka biznesowa pozwoli Ci osiągnąć szybszy wzrost biznesu, lepiej zrozumieć klientów i posiadać bardziej przewidywalne wyniki. Dołącz do naszego webinaru żeby dowiedzieć się o podstawach analityki w e-commerce
          </p>
          <a className={classnames('btn', ctaCss.ctaFilled)} href="https://share.hsforms.com/1_H90bhyqRJepkWQpQjCHOg38fq4" target='_blank' rel="noreferrer">Zarezerwuj swoje miejsce</a>
        </div>
      </div>
    </div>
    <div className={classnames('container', 'col-xxl-10', 'px-4', 'py-5')}>
      <div className={classnames('row', 'flex-lg-row-reverse', 'align-items-center')}>
        <Testimonial
          person="Bogdan Tyskyy"
          title="Chief Revenue Officer"
          url="https://callpage.io"
        >
          "Używamy Fakturowni. Przed integracją z Probe potrzebowaliśmy 10-15 dni
          żeby mieć pełne wyniki miesięczne. Dzisiaj mamy je pierwszego dnia
          miesiąca bez żadnej pracy."
        </Testimonial>
      </div>
    </div>
    <div className={classnames('container', 'col-xxl-10', 'px-4', 'py-5')}>
      <div className={classnames('row', 'text-center', 'align-items-center', 'g-5', 'pt-5')}>
        <h2 className={classnames("mt-0", "display-4")}>Dla kogo</h2>
        <img src={dotsImage} className="d-block" alt="Create icon" width="80" height="20" loading="lazy" />
      </div>
      <div className={classnames('row', 'justify-content-center', 'g-5', 'py-5', 'd-flex')}>
        <div className={classnames("col", "d-flex", 'flex-column', css.valueCard)}>
          <h3>Prowadzisz biznes e-commerce</h3>
          <p>Zrozumiesz jakie produkty przynoszą najwięcej przychodu, w co warto inwestować a co przestać sprzedawać</p>
        </div>
        <div className={classnames("col", "d-flex", 'flex-column', css.valueCard)}>
          <h3>Pracujesz w dziale księgowości bądź operacji</h3>
          <p>Doradzimy jak wystawiać faktury, żeby analityka na ich podstawie była pewna</p>
        </div>
      </div>
    </div>
    <div className={classnames('container', 'col-xxl-10', 'px-4', 'py-5')}>
      <div className={classnames('row', 'text-center', 'align-items-center', 'g-5', 'pt-5')}>
        <h2 className={classnames("mt-0", "display-4")}>Czego się dowiesz</h2>
        <img src={dotsImage} className="d-block" alt="Create icon" width="80" height="20" loading="lazy" />
      </div>
      <div className={classnames('row', 'justify-content-center', 'g-5', 'py-5', 'd-flex')}>
        <div className={classnames("col", "d-flex", 'flex-column', css.valueCard)}>
          <h3>1. Składowe wzrostu i segmentacja</h3>
          <p>Porozmawiamy o podstawach segmentacji przychodów. Dowiesz się czym jest segmentacja, jak podzielić swój przychód na różne części. Na jakich segmentach warto sie skupic na początku.</p>
        </div>
        <div className={classnames("col", "d-flex", 'flex-column', css.valueCard)}>
          <h3>2. Nowy biznes a stali klienci</h3>
          <p>Porozmawiamy o dwóch częściach które wpływają na wzrost Twojego biznesu. Dowiesz się dlaczego warto śledzić je osobno i w jaki sposób możesz proaktywnie poprawić wyniki swojej firmy.</p>
        </div>
      </div>
      <div className={classnames('row', 'justify-content-center', 'g-5', 'py-5', 'd-flex')}>
        <div className={classnames("col", "d-flex", 'flex-column', css.valueCard)}>
          <h3>3. Jak zebrać dane</h3>
          <p>Opowiemy o tym w jaki sposób zebrać podstawowe dane, żeby stworzyć pierwszą analizę. Na co warto zwracać uwagę przy wystawianiu nowych faktur, żeby analityka była prostsza.</p>
        </div>
        <div className={classnames("col", "d-flex", 'flex-column', css.valueCard)}>
          <h3>4. Automatyzacja</h3>
          <p>Porozmawiamy o tym jak zautomatyzować analitykę i kiedy to się nie opłaca.</p>
        </div>
      </div>
    </div>
    <div className={classnames('container', 'col-xxl-10', 'px-4', 'py-5')}>
      <div className={classnames('row', 'text-center', 'align-items-center', 'g-5', 'pt-5')}>
        <h2 className={classnames("mt-0", "display-4")}>6 października, 15:00 na ZOOM</h2>
        <img src={dotsImage} className="d-block" alt="Create icon" width="80" height="20" loading="lazy" />
      </div>
      <div className={classnames('row', 'justify-content-center', 'g-5', 'py-5', 'd-flex')}>
        <div className={classnames("col", "d-flex", 'flex-column', css.valueCard)}>
          <p>Spotykamy się 6 października, na ZOOM o godzinie 15. Przewidywana długość webinaru to ~40 min + czas na Q&A. Wszystkie zarejestrowane osoby dostaną link na ZOOM w dniu webinaru. Webinar poprowadzimy w języku polskim.</p>
        </div>
      </div>
    </div>
    <CustomersPromo />
    <div className={classnames('container', 'col-lg-5', 'px-4', 'py-5')}>
      <div className={classnames('row', 'text-center', 'align-items-center', 'g-5', 'pt-5')}>
        <h2 className={classnames("mt-0", "display-4")}>Zarezerwuj swoje miejsce</h2>
      </div>
      <div id='hsform' className={classnames('row', 'text-center', 'align-items-center', 'g-5', 'pt-5')}>
      </div>
    </div>
  </Layout>
}

export default Ecommerce
